/* Google Fonts Import Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("http://fonts.cdnfonts.com/css/segoe-ui-4?styles=18006,18004");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap");

.ClassManagement_wrapper {
  background-color: #f3f3fa;
  height:90vh;
  padding-left: 20% !important;
  padding-top: 20px;
  padding-right: 40px;
  overflow-y:scroll;
  padding-bottom: 5% !important;
}
.ClassManagement_buttons button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #666666;
  border-radius: 4px;
  margin: 3px;
  width: 8%;
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #2a2f44;
}

.ClassManagement_buttons .active_button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border: 1px solid #5a5eee;
  color: #fff;
}

.ClassManagement_buttons button:focus,
.ClassManagement_buttons button:active,
.ClassManagement_buttons button:hover {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border: 1px solid #5a5eee;
  color: #fff;
}
.ClassManagement_body {
  background-color: #ffffff;
  width: 100%;
  height: 1000px;
  padding: 15px 20px 10px 15px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.class-mgt-error{
  text-align: left;
font: normal normal 600 12px/26px Poppins;
letter-spacing: 0px;
color: #F82641;
}

.ClassManagement_body .Searchbar .form-control {
  height: 40px;
  width: 40%;
  font: normal normal normal 12px/16px Poppins;
  background: #f3f3fa 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3fa;
  border-radius: 20px;
  padding-left: 30px;
}
.ClassManagement_body .Searchbar .serch-icon {
  display: block;
  position: relative;
  top: 25px;
  left: 32px;
  font-size: 20px;
}
.ClassManagement_body button {
  /* background: #5a5eee 0% 0% no-repeat padding-box; */
  border-radius: 4px;
  width: 25%;
  padding: 5px 5px 5px 5px;
  opacity: 1;
  margin-right: 10%;
  border:2px solid  #5a5eee;
  color: #5a5eee;
  font-weight: 600;
  font-size:12px;
  /* normal normal normal 12px/27px Segoe UI; */
  float: right;
}

/* Country Table CSS */

.ClassManagement_Country_table {
  margin-top: 20px;
}

.ClassManagement_Country_table tr th {
  font-size: 14px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  padding-left: 50px;
  text-align: justify;
  border-bottom: 5px solid #F8F8FB;
  border-width: 10%;
}

#ClassManagement_Country_table tr {
  border-bottom: 5px solid #F8F8FB;
  height: 60px;
}

#ClassManagement_Country_table tr td {
  font-size: 13px;
  color: #555555;
  font-style: Poppins;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* background-color: #5A5EEE62; */
}

#ClassManagement_Country_table tr:hover{
  background-color: #5A5EEE62;
  color:#111111;
  font-weight: 900;
}

#ClassManagement_Country_table tr td:hover{
  color:#111111;
  font-weight: bold;
}

#ClassManagement_Country_table tr td button {
  border-radius: 4px;
  width: 35%;
  float: left;
}

#ClassManagement_Country_table tr td span img {
  /* background: #5a5eee 0% 0% no-repeat padding-box; */
  border-radius: 10%;
  padding: 0px 60px 0px 0px;
  color: #fff;
  width: 100px;
}
#ClassManagement_table tr td span img {
  /* background: #5a5eee 0% 0% no-repeat padding-box; */
  border-radius: 10%;
  padding: 0px 60px 0px 0px;
  color: #fff;
  width: 100px;
}
#ClassManagement_table tr td button {
  /* background: #f7734a 0% 0% no-repeat padding-box; */
  border-radius: 4px;
  width: 35%;
  float: left;
}
/* Create New Country Panel */

.create-country_pannel p {
  font: normal normal normal 14px/37px Poppins;
  letter-spacing: 0px;
  color: #5a5eee;
  font-weight: 500;
  padding-left: 20px;
}
.create-country_pannel i {
  font: normal normal normal 22px/37px Poppins;
  position: relative;
  top: 30px;
  float: right;
  padding-right: 30px;
}
.create-country_pannel hr {
  position: relative;
  top: -18px;
  color: #5a5eee;
  left: 20px;
  width: 55%;
  height: 2px;
  opacity: 100%;
}
.create-country_form {
  margin-left: 20px;
}
.create-country_form label {
  font: normal normal normal 14px/32px Poppins;
  letter-spacing: 0px;
  color: #111111;
  margin-bottom: 15px;
}
.create-country_form input {
  border: 1px solid #666666;
  border-radius: 8px;
  /* margin-bottom: 40px; */
  text-align: left;
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  color: #111111;
  opacity: 0.5;
}
.create-country_form_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  float: right;
  margin-right: 20px;
  border: 1px solid #5a5eee;
  color: #fff;
  font: normal normal normal 12px/33px Poppins;
  padding: 0px 10px 0px 10px;
  position: absolute;
  bottom: 10%;
  right: 30px;
}

/* Create ExamBoard Pannel */
.examboard_create_button button{
  border-radius: 4px;
  width: 28%;
  padding: 5px 5px 5px 5px;
  opacity: 1;
  margin-right: 5%;
  border:2px solid  #5a5eee;
  color: #5a5eee;
  font-weight: 600;
  font-size:12px;
  /* normal normal normal 12px/27px Segoe UI; */
  float: right;
}

.grade_create_button button{
  margin-right: 5%;
}

.subject_create_button button{
  margin-right: 5%;
}

.level_create_button button{
  margin-right: 5%;
}

.ClassManagement_Exam_Board_table {
  margin-top: 20px;
}
.ClassManagement_Exam_Board_table tr th {
  font-size: 14px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  padding-left: 10%;
  text-align:left;
  border-bottom: 5px solid #F8F8FB;
  border-width: 10%;
}
#ClassManagement_Exam_Board_table tr {
  border-bottom: 5px solid #F8F8FB;
}
#ClassManagement_Exam_Board_table tr td {
  font-size: 13px;
  color:#707070;
  font-style: Poppins;
  text-align:left;
  padding-left: 9%;
  padding-top: 15px;
  padding-bottom: 10px;
  /* background-color: #5A5EEE62; */
  height: 60px;
}
#ClassManagement_Exam_Board_table tr:hover{
  background-color: #5A5EEE62;
  color:#111111;
  font-weight: 900;
}
#ClassManagement_Exam_Board_table tr td:hover{
  color:#111111;
  font-weight: bold;
}
#ClassManagement_Exam_Board_table tr td button {
  border-radius: 4px;
  width: 35%;
  float: left;
}
#ClassManagement_Exam_Board_table tr td span img {
  /* background: #5a5eee 0% 0% no-repeat padding-box; */
  border-radius: 10%;
  padding: 0px 55px 0px 0px;
  color: #fff;
  width: 100px;
}
.create-exam_board_pannel p {
  font: normal normal normal 14px/37px Poppins;
  letter-spacing: 0px;
  color: #5a5eee;
  font-weight: 500;
  padding-left: 20px;
}
.create-exam_board_pannel i {
  font: normal normal normal 22px/37px Poppins;
  position: relative;
  top: 30px;
  float: right;
  padding-right: 30px;
}
.create-exam_board_pannel hr {
  position: relative;
  top: -18px;
  left: 20px;
  color: #5a5eee;
  width: 65%;
  height: 2px;
  opacity: 100%;
}
.create-exam_board_form {
  margin-left: 20px;
}
.create-exam_board_form label {
  font: normal normal normal 14px/32px Poppins;
  letter-spacing: 0px;
  color: #111111;
  margin-bottom: 10px;
  font-weight: 500;
}
.create-exam_board_form input {
  border: 1px solid #666666;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: left;
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  color: #111111;
  opacity: 0.5;
}
.create-exam_board_form select {
  border: 1px solid #666666;
  font: normal normal normal 10px/40px Poppins;
  border-radius: 6px;
  color: #111111;
  font-weight: 600;
  width: 100%;
  opacity: 0.7;
  height: 40px;
  margin-bottom: 20px;
  padding-left: 10px;
}
.create-exam_board_form_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  float: right;
  margin-right: 20px;
  border: 1px solid #5a5eee;
  color: #fff;
  font: normal normal normal 12px/33px Poppins;
  padding: 0px 10px 0px 10px;
  position: absolute;
  bottom: 10%;
  right: 30px;
}

/* Create Level Pannel */
/* Table Level Data */

.ClassManagement_Level_table{
  margin-top: 20px;
}
.ClassManagement_Level_table tr th {
  font-size: 14px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  padding-left: 50px;
  text-align: justify;
  border-bottom: 5px solid #F8F8FB;
  border-width: 10%;
}
#ClassManagement_Level_table tr {
  border-bottom: 5px solid #F8F8FB;
  /* border-bottom: 5px solid #F8F8FB; */
}
#ClassManagement_Level_table tr td {
  font-size: 13px;
  color: #555555;
  font-style: Poppins;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* background-color: #5A5EEE62; */
  height: 60px;
}
#ClassManagement_Level_table tr:hover{
  background-color: #5A5EEE62;
  color:#111111;
  font-weight: 900;
}
#ClassManagement_Level_table tr td:hover{
  color:#111111;
  font-weight: bold;
}
#ClassManagement_Level_table tr td button {
  border-radius: 4px;
  width: 35%;
  float: left;
}
#ClassManagement_Level_table tr td span img {
  /* background: #5a5eee 0% 0% no-repeat padding-box; */
  border-radius: 10%;
  padding: 0px 60px 0px 0px;
  color: #fff;
  width: 100px;
}

.create-Level_pannel p {
  font: normal normal normal 14px/37px Poppins;
  letter-spacing: 0px;
  color: #5a5eee;
  font-weight: 500;
  padding-left: 20px;
}
.create-Level_pannel i {
  font: normal normal normal 22px/37px Poppins;
  position: relative;
  top: 30px;
  float: right;
  padding-right: 30px;
}
.create-Level_pannel hr {
  position: relative;
  top: -18px;
  left: 20px;
  color: #5a5eee;
  width: 65%;
  height: 2px;
  opacity: 100%;
}
.create-Level_form {
  margin-left: 20px;
}
.create-Level_form label {
  font: normal normal normal 14px/32px Poppins;
  letter-spacing: 0px;
  color: #111111;
  margin-bottom: 10px;
  font-weight: 500;
}
.create-Level_form input {
  border: 1px solid #666666;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: left;
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  color: #111111;
  opacity: 0.5;
}
.create-Level_form select {
  border: 1px solid #666666;
  font: normal normal normal 10px/40px Poppins;
  border-radius: 6px;
  color: #111111;
  font-weight: 600;
  width: 100%;
  opacity: 0.7;
  height: 40px;
  margin-bottom: 20px;
  padding-left: 10px;
}
.create-Level_form_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  float: right;
  margin-right: 20px;
  border: 1px solid #5a5eee;
  color: #fff;
  font: normal normal normal 12px/33px Poppins;
  padding: 0px 10px 0px 10px;
  position: absolute;
  bottom: 10%;
  right: 30px;
}
/* Create Grade Pannel */
.panel-wrap_grade {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30em;
  transform: translateX(100%);
  transition: 0.5s ease-out;
  /* overflow-x:hidden; */
}
.panel_grade {
  position: absolute;
  top: 10%;
  bottom: 0;
  left: 0;
  height: 90%;
  right: -5%;
  background: #ffffff;
  overflow:hidden;
  padding: 0em 1em 0em 1em;
}
[type="text"]:checked ~ .panel-wrap_grade {
  transform: translateX(0%);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  font-size: 18px;
}
h3 {
  margin: 0;
}
p {
  line-height: 1.4;
  margin: 2em 0 0 0;
}
[type="text"] {
  font-size: 1em;
}

.create-Grade_pannel p {
  font: normal normal normal 14px/37px Poppins;
  letter-spacing: 0px;
  color: #5a5eee;
  font-weight: 500;
  padding-left: 20px;
}
.create-Grade_pannel i {
  font: normal normal normal 22px/37px Poppins;
  position: relative;
  top: 30px;
  float: right;
  padding-right: 30px;
}
.create-Grade_pannel hr {
  position: relative;
  top: -18px;
  left: 20px;
  color: #5a5eee;
  width: 50%;
  height: 2px;
  opacity: 100%;
}
.create-Grade_form {
  margin-left: 20px;
}
.create-Grade_form span {
  position: relative;
  left: 300px;
  top: -55px;
}
.create-Grade_form label {
  font: normal normal normal 14px/32px Poppins;
  letter-spacing: 0px;
  color: #111111;
  margin-bottom: 10px;
  font-weight: 500;
}
.create-Grade_form input {
  border: 1px solid #666666;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: left;
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  color: #111111;
  opacity: 0.5;
}
.create-Grade_form select {
  border: 1px solid #666666;
  font: normal normal normal 10px/40px Poppins;
  border-radius: 6px;
  color: #111111;
  font-weight: 600;
  width: 100%;
  opacity: 0.7;
  height: 40px;
  margin-bottom: 10px;
  padding-left: 10px;
}
.create-Grade_form .permission_multiselect_dropdown{
  /* width: 85%; */
  margin-left: 0px;
  border-radius: 10px;
  border: 1px solid none;
  font: normal normal normal 14px/33px Poppins;
  letter-spacing: 0px;
  color: #707070;
}

.search-wrapper {
  height: 40px;
}
.searchBox {
  height: 20px;
  border: 1px solid #fff;
}
.optionContainer {
  font: normal normal normal 12px/33px Poppins;
  white-space: nowrap;
  line-height: 0px;
}
.create-Grade_form_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  float: right;
  margin-right: 20px;
  border: 1px solid #5a5eee;
  color: #fff;
  font: normal normal normal 12px/33px Poppins;
  padding: 0px 10px 0px 10px;
  position: absolute;
  bottom: 5%;
  right: 30px;
}
.css-b62m3t-container{
  font-size: 12px;
  margin-bottom: 10px;
}
/* Grade Table data */
.ClassManagement_Grade_table{
  margin-top: 20px;
}
.ClassManagement_Grade_table tr th {
  font-size: 14px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  padding-left: 50px;
  text-align: justify;
  border-bottom: 5px solid #F8F8FB;
  border-width: 10%;
}
#ClassManagement_Grade_table tr {
  border-bottom: 5px solid #F8F8FB;
  /* border-bottom: 5px solid #F8F8FB; */
}
#ClassManagement_Grade_table tr td {
  font-size: 13px;
  color: #555555;
  font-style: Poppins;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* background-color: #5A5EEE62; */
  height: 60px;
}
#ClassManagement_Grade_table tr:hover{
  background-color: #5A5EEE62;
  color:#111111;
  font-weight: 900;
}
#ClassManagement_Grade_table tr td:hover{
  color:#111111;
  font-weight: bold;
}
#ClassManagement_Grade_table tr td button {
  border-radius: 4px;
  width: 35%;
  float: left;
}
#ClassManagement_Grade_table tr td span img {
  /* background: #5a5eee 0% 0% no-repeat padding-box; */
  border-radius: 10%;
  padding: 0px 60px 0px 0px;
  color: #fff;
  width: 100px;
}
/* Create Delete Grade Pannel */

.Grade_delete_panel p {
  font: normal normal normal 14px/37px Poppins;
  letter-spacing: 0px;
  color: #5a5eee;
  font-weight: 500;
  padding-left: 20px;

}
.Grade_delete_panel {
  margin-top: 0px;
  overflow: hidden;
}
.Grade_delete_panel .Grade_delete_Circle {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 50%;
  padding: 18px;
  color: #fff;
}
.Grade_Creator_Circle {
  font: normal normal normal 20px/40px Poppins;
  position: relative;
  top: 10px;
  left: 10px;
  background-color: #5a5eee;
  border-radius: 70%;
  padding: 15px 20px 15px 20px;
  color: #ffffff;
}
.GradeTag p{
  position: relative;
  top: -15px;
}
.Grade_Creator_title h5 {
  font: normal normal normal 14px/29px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  position: relative;
  top: 0px;
  left: 40px;
}
.Grade_Creator_title button {
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  color: #5a5eee;
  border: none;
  background-color: #fff;
  opacity: 1;
  position: relative;
  top: -10px;
  left: 40px;
  cursor: pointer;
}
.Grade_Creator_close_Icon {
  padding-top: 0px;
  float: right;
  font-size: 23px;
  padding-right: 40px;
  cursor: pointer;
}
.create-Grade_pannel i {
  font: normal normal normal 22px/37px Poppins;
  position: relative;
  top: 30px;
  float: right;
  padding-right: 30px;
}
.create-Grade_pannel hr {
  position: relative;
  top: -18px;
  left: 20px;
  color: #5a5eee;
  width: 50%;
  height: 2px;
  opacity: 100%;
}
.Grade_delete_form {
  margin-left: 20px;
}
.Grade_delete_form span {
  position: relative;
  left: 300px;
  top: -55px;
}
.Grade_delete_form label {
  font: normal normal normal 14px/32px Poppins;
  letter-spacing: 0px;
  color: #111111;
  /* margin-bottom: 10px; */
  font-weight: 500;
}
.Grade_delete_form input {
  border: 1px solid #666666;
  border-radius: 8px;
  /* margin-bottom: 10px; */
  text-align: left;
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  color: #111111;
  opacity: 0.5;
}
.Grade_delete_form select {
  border: 1px solid #666666;
  font: normal normal normal 10px/40px Poppins;
  border-radius: 6px;
  color: #111111;
  font-weight: 600;
  width: 100%;
  opacity: 0.7;
  height: 40px;
  margin-bottom: 20px;
  padding-left: 10px;
}
.Grade_delete_form_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  float: right;
  margin-right: 20px;
  border: 1px solid #5a5eee;
  color: #fff;
  font: normal normal normal 12px/33px Poppins;
  padding: 0px 10px 0px 10px;
  position: absolute;
  bottom: 10%;
  right: 30px;
}
.Grade_delete_form input[type="color"] {
  border: 1px solid #666666;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: left;
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  color: #111111;
  opacity: 0.5;
}
/* Subject Table */
.ClassManagement_Subject_table{
  margin-top: 20px;
  border-bottom: 1px solid #F8F8FB;
}
.ClassManagement_Subject_table tr th {
  font-size: 14px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  padding-left: 50px;
  text-align: justify;
  border-bottom: 5px solid #F8F8FB;
  border-width: 10%;
  height: 60px;
}
#ClassManagement_Subject_table tr {
  border-bottom: 5px solid #fff;
  /* border-bottom: 5px solid #F8F8FB; */
}
#ClassManagement_Subject_table tr td {
  font-size: 13px;
  color: #555555;
  font-style: Poppins;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* background-color: #5A5EEE62; */
}
#ClassManagement_Subject_table tr:hover{
  background-color: #5A5EEE62;
  color:#111111;
  font-weight: 900;
}
#ClassManagement_Subject_table tr td:hover{
  color:#111111;
  font-weight: bold;
}
#ClassManagement_Subject_table tr td button {
  border-radius: 4px;
  width: 35%;
  float: left;
}
#ClassManagement_Subject_table tr td span img {
  /* background: #5a5eee 0% 0% no-repeat padding-box; */
  border-radius: 10%;
  padding: 0px 60px 0px 0px;
  color: #fff;
  width: 100px;
}

.ClassManagement_table {
  margin-top: 20px;
}
.ClassManagement_table tr th {
  /* font: normal normal medium 25px/30px Poppins !important; */
  font-size: 14px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  /* padding-bottom: 10px; */
  padding-left: 30px;
  text-align: justify;
  /* padding-right: 20px; */
  /* border-bottom: 5px solid #f8f8fb; */
}
#ClassManagement_table tr {
  border-bottom: 5px solid #fff;
}
#ClassManagement_table tr td {
  font-size: 13px;
  color: #555555;
  font-style: Poppins;
  padding-left: 26px;
  padding-top: 15px;
}
