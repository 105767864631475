/* Google Fonts Import Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("http://fonts.cdnfonts.com/css/segoe-ui-4?styles=18006,18004");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap");
a {
  text-decoration: none;
}
.SubscriptionandPayment_wrapper {
  background-color: #f3f3fa;
  height: 90vh;
  overflow-y: scroll !important;
  padding-left: 20% !important;
  padding-bottom: 5% !important;
}
.SubscriptionandPayment {
  background-color: #ffffff;
  width: 95%;
  padding: 15px 20px 25px 15px;
  margin-top: 20px;
}
.SubscriptionandPayment .Searchbar .form-control {
  height: 40px;
  width: 20%;
  font: normal normal normal 12px/16px Poppins;
  background: #f3f3fa 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3fa;
  border-radius: 20px;
  padding-left: 30px;
  margin-top: 20px;
}
.Searchbar .serch-icon {
  display: block;
  position: relative;
  top: 25px;
  left: 32px;
  font-size: 20px;
}

#SubscriptionandPayment_table tr th {
  /* font: normal normal medium 25px/30px Poppins !important; */
  font-size: 13px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  text-align: center;
}
#SubscriptionandPayment_table tr {
  border-bottom: 5px solid #f8f8fb;
}
#SubscriptionandPayment_table tr td {
  font-size: 12px;
  color: #555555;
  font-style: Poppins;
  text-align: center;
}
#SubscriptionandPayment_dropdown select {
  background-color: #02a640;
  color: #ffffff;
  height: 35px;
  font-size: 12px;
  width: 45%;
  border-radius: 10px;
  border: 1px solid #02a640;
  text-align: left;
  margin: 10px;
  -webkit-appearance: none;
  appearance: none;
  padding: 5px 6px;
}

.subscribe-wrapper {
  position: relative;
}

.subscribe-wrapper::after {
  content: "▼";
  font-size: 0.5rem;
  top: 30px;
  right: 100px;
  position: absolute;
  color: #ffffff;
}

#SubscriptionandPayment_dropdown select option {
  background-color: #f3f3fa;
  color: #696969;
  border: 2px solid #5a5eee;
}
#SubscriptionandPayment_table button {
  color: #5a5eee;
  border: none;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: #555555;
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  z-index: 1;
}
.SubscriptionViewmore_card {
  background: #ffffff;
  margin: 0 auto;
  width: 58%;
  height: 500px;
  z-index: 2;
  border-radius: 4px;
  padding: 30px;
  position: absolute;
  top: 160px;
  box-shadow: 0px 3px 6px #e8e8ff;
  border: 1px solid #d3d3ff;
  right: 15%;
}
.SubscriptionViewmore_card_Icon {
  margin: 30px;
}
.SubscriptionViewmore_card_Icon h5 {
  background-color: #5a5eee;
  color: #ffffff;
  padding: 30px;
  border-radius: 50%;
}
.SubscriptionViewmore_card_Icon_data h5 {
  font: normal normal normal 18px/41px Poppins;
  letter-spacing: 0px;
  font-weight: 500;
  color: #111111;
  padding-top: 35px;
}
.SubscriptionViewmore_card_Icon_data p {
  font: normal normal normal 13px/33px Poppins;
  letter-spacing: 0px;
  color: #707070;
  position: relative;
  padding-left: 2px;
  top: -20%;
  font-weight: 600;
}
.SubscriptionViewmore_card_Icon_data span {
  color: #02a640;
  font-weight: 800;
}
.SubscriptionViewmore_close_Icon {
  padding-top: 35px;
  float: right;
  font-size: 23px;
  padding-right: 20px;
}
.SubscriptionViewmore_close_Icon a {
  color: #5a5eee;
}

.SubscriptionViewmore-form label {
  font: normal normal normal 12px/32px Poppins;
  letter-spacing: 0px;
  font-weight: 600;
  color: #111111;
  opacity: 1;
  margin-left: 90px;
}
.SubscriptionViewmore-form input {
  text-align: left;
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  font-weight: 500;
  color: #707070;
  background: #f9faff 0% 0% no-repeat padding-box;
  border: 1px solid #5a5eee5a;
  border-radius: 10px;
  width: 80%;
  height: 20%;
  margin-right: 30px;
  margin-left: 88px;
  margin-bottom: 40px;
}
.pagebutton {
  display: inline;
  padding: 10px 15px 10px 15px;
  border: 2px solid #0075ff;
  background-color: #0075ff;
  margin-top: 20px;
  margin-left: 10px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
}
/* CouponCode Css */
.CouponCode_Wrapper {
  background-color: #f3f3fa;
  height: 90vh;
  overflow-y: scroll;
  padding-left: 20% !important;
}
/* .CouponCodeandPayment {
  background-color: #ffffff;
  width: 95%;
  padding: 15px 20px 10px 15px;
  margin-top: 20px;
} */
.tabset > input[type="radio"] {
  position: absolute;
  left: -200vw;
}

.tabset .tab-panel {
  display: none;
  background-color: #fff;
  width: 95%;
  /* height: 500px; */
  margin-bottom: 30px;
  padding: 5px;
}

.tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
.tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
.tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
.tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
.tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
.tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
  display: block;
}

.tabset > label {
  position: relative;
  display: inline-block;
  padding: 25px 40px 10px 0px;
  border-bottom: 0;
  cursor: pointer;
  text-align: left;
  font: normal normal normal 17px/41px Poppins;
  font-weight: 500;
  letter-spacing: 0px;
  color: #111111;
  opacity: 0.5;
}
.tabset > label::before,
.tabset > label::after {
  content: "";
  position: absolute;
  left: 15px;
  bottom: 10px;
  width: 22px;
  height: 4px;
}

/* .tabset > label:hover,
.tabset > input:focus + label {
  color: #5a5eee;
  opacity: 1;
} */
.CouponCode_tab1 .Searchbar .form-control {
  height: 40px;
  width: 40%;
  font: normal normal normal 12px/16px Poppins;
  background: #f3f3fa 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3fa;
  border-radius: 10px;
  padding-left: 30px;
  margin-top: 20px;
}
.Searchbar .serch-icon {
  display: block;
  position: relative;
  top: 25px;
  left: 32px;
  font-size: 20px;
}
.CouponCode_tab1 button {
  background-color: #5a5eee;
  border: 1px solid #5a5eee;
  font: normal normal normal 13px/27px Segoe UI;
  letter-spacing: 0px;
  color: #fafafa;
  float: right;
  margin: 20px;
  border-radius: 5px;
  padding: 3px 12px 3px 12px;
}
.CouponCode_table tr th {
  /* font: normal normal medium 25px/30px Poppins !important; */
  font-size: 14px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  padding-left: 30px;
  text-align: justify;
  padding-right: 20px;
}
#CouponCode_table tr {
  border-bottom: 5px solid #f8f8fb;
}
#CouponCode_table tr td {
  font-size: 13px;
  color: #555555;
  font-style: Poppins;
  padding-left: 35px;
}
.Coupon_Name h5 {
  font-size: 13px;
  font-family: Poppins;
  font-weight: 600;
  letter-spacing: 0px;
  padding-top: 20px;
  color: #162880;
}
.Coupon_Name span {
  font-size: 10px;
  font-family: Poppins;
  letter-spacing: 0px;
  position: relative;
  top: -12px;
}
#CouponCode_table button {
  position: relative;
  left: -45%;
  width: 85px;
}
#view_coupon button {
  color: #5a5eee;
  background-color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 800;
}
#Coupon__disable button {
  background-color: #4b4b4b;
  opacity: 0.5;
}
/* CouponCode Creator Panel */

.coupon-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30em;
  transform: translateX(100%);
  transition: 0.5s ease-out;
}
.coupon {
  position: absolute;
  top: 10%;
  bottom: 0;
  left: 0;
  height: 90%;
  right: -5%;
  background: #ffffff;
  overflow: auto;
  padding: 1em;
}
.Coupone_Creator_Circle {
  font: normal normal normal 20px/40px Poppins;
  position: relative;
  top: 10px;
  left: 10px;
  background-color: #5a5eee;
  border-radius: 70%;
  padding: 12px 20px 12px 20px;
  color: #ffffff;
}
.Coupone_Creator_title h5 {
  font: normal normal normal 14px/29px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  position: relative;
  top: 10px;
  left: 40px;
}
.Coupone_Creator_title button {
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  color: #5a5eee;
  border: none;
  background-color: #fff;
  opacity: 1;
  position: relative;
  top: -2px;
  left: 40px;
  cursor: pointer;
}
.Coupone_Creator_close_Icon {
  padding-top: 10px;
  float: right;
  font-size: 23px;
  padding-right: 40px;
  cursor: pointer;
}
.dropdown-check-list {
  display: inline-block;
  color: #666666;
  font: normal normal normal 14px/33px Poppins;
  border-radius: 10px;
}
.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 50px 5px 10px;
  border: 1px solid #ccc;
  background: #f9faff;
  border: none;
}
.dropdown-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 2px solid black;
  border-top: 2px solid black;
  padding: 5px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}
.dropdown-check-list ul.items {
  padding: 15px;
  display: none;
  margin: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #008ded34;
  border-top: none;
}
.dropdown-check-list ul.items li {
  list-style: none;
}
.Coupone_Creator_top_form {
  padding-top: 20px;
  margin-bottom: 10px;
}
.Coupone_Creator_dropdown span {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #666666;
  width: 250px;
  background: #f9faff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
}
.Coupone_Creator_top_form label {
  font: normal normal normal 14px/32px Poppins;
  color: #111111;
  font-weight: 500;
  padding-left: 5px;
}
.Coupone_Creator_top_form input[type="text"] {
  background: #f9faff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  width: 250px;
  font: normal normal normal 12px/27px Poppins;
}
.Coupone_Creator_bottom_form label {
  font: normal normal normal 14px/32px Poppins;
  color: #111111;
  font-weight: 500;
  padding-left: 5px;
  padding-bottom: 5px;
}
.Coupone_Creator_bottom_form input[type="text"] {
  background: #f9faff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  width: 97%;
  font: normal normal normal 12px/27px Poppins;
  margin-bottom: 20px;
}
.Coupone_Creator_bottom_form i {
  float: right;
  position: relative;
  top: -55px;
  left: -30px;
  font-size: 20px;
}
.Coupone_Creator_bottom_form1 label {
  font: normal normal normal 14px/32px Poppins;
  color: #111111;
  font-weight: 500;
  padding-left: 5px;
  padding-bottom: 5px;
}
.Coupone_Creator_bottom_form1 input[type="text"] {
  background: #f9faff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  width: 250px;
  font: normal normal normal 12px/27px Poppins;
  margin-bottom: 20px;
}
.Coupone_Creator_bottom_form2 label {
  font: normal normal normal 14px/32px Poppins;
  color: #111111;
  font-weight: 500;
  padding-left: 5px;
  padding-bottom: 5px;
}
.Coupone_Creator_bottom_form2 input[type="date"] {
  background: #f9faff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  width: 250px;
  font: normal normal normal 12px/27px Poppins;
  margin-bottom: 20px;
}
.Coupone_Creator_button {
  float: right;
  padding-right: 20px;
}
.Coupone_Creator_button button {
  color: #fff;
  background-color: #5a5eee;
  font: normal normal normal 12px/33px Poppins;
  border: none;
  padding: 0px 10px 0px 10px;
  border-radius: 8px;
}
/* CouponCodeCreator Css */
.CouponCodeCreator_title {
  margin: 0px 10px 10px 0px;
  padding-right: 20px;
}
.CouponCodeCreator_title h5 {
  font: normal normal normal 14px/37px Poppins;
  font-weight: 500;
  letter-spacing: 0px;
  color: #5a5eee;
}
.CouponCodeCreator_title hr {
  color: #5a5eee;
  width: 55%;
  position: relative;
  top: -15px;
  height: 3px;
  opacity: 1;
}
.CouponCodeCreator_Icon {
  padding-top: 8px;
  float: right;
  font-size: 23px;
  padding-right: 20px;
  cursor: pointer;
  color: #403178;
}
.CouponCodeCreator_title label {
  font: normal normal normal 14px/32px Poppins;
  color: #111111;
  font-weight: 500;
  padding-left: 5px;
  padding-bottom: 5px;
}

.CouponCodeCreator_title input[type="checkbox"] {
  /* background: #f9faff 0% 0% no-repeat padding-box; */
  border-radius: 10px;
  border: 1px solid #666666;
  /* width: 250px; */
  font: normal normal normal 12px/27px Poppins;
  /* margin-bottom: 20px; */
}
.CouponCodeCreator_title input {
  border-radius: 10px;
  border: 1px solid #666666;
  /* width: 250px; */
  font: normal normal normal 12px/27px Poppins;
  /* margin-bottom: 20px; */
}
.CouponCodeCreator_full_form input[type="date"] {
  border-radius: 10px;
  border: 1px solid #666666;
  /* padding-left: 0px; */
  width: 98%;
  font: normal normal normal 12px/27px Poppins;
}
.CouponCodeCreator_full_form i {
  float: right;
  position: relative;
  top: -37px;
  left: -30px;
  font-size: 20px;
}
.CouponCodeCreator_dropdown span {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #666666;
  width: 240px;
  /* background: #f9faff 0% 0% no-repeat padding-box; */
  border-radius: 10px;
  border: none;
  height: 42px;
}
.coupon_Creator_input_form select {
  border: 1px solid #666666;
  padding: 9px;
  border-radius: 10px;
  font-size: 14px;
  width: 95%;
}
.coupon_Creator_input_form Select option {
  font-size: 12px;
}
.CouponCodeCreator-check-list {
  display: inline-block;
  color: #666666;
  font: normal normal normal 14px/33px Poppins;
  border-radius: 10px;
}
.CouponCodeCreator-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px 5px 10px;
  border: 1px solid #ccc;
  /* background: #f9faff; */
  border: 1px solid #666666;
}
.CouponCodeCreator-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 2px solid black;
  border-top: 2px solid black;
  padding: 5px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.CouponCodeCreator-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}
.CouponCodeCreator-check-list ul.items {
  padding: 15px;
  display: none;
  margin: 0;
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #008ded34;
  border-top: none;
}
.CouponCodeCreator-check-list ul.items li {
  list-style: none;
}
/* CouponInvitaion CSS */
.CouponInvitaion_title {
  margin: 0px 10px 10px 0px;
  /* overflow-y:scroll;
  height: 120vh; */
}
.CouponInvitaion_title h5 {
  font: normal normal normal 14px/37px Poppins;
  font-weight: 500;
  letter-spacing: 0px;
  color: #5a5eee;
}
.CouponInvitaion_title hr {
  color: #5a5eee;
  width: 55%;
  position: relative;
  top: -15px;
  height: 3px;
  opacity: 1;
}
.CouponInvitaion_Icon {
  padding-top: 8px;
  float: right;
  font-size: 23px;
  padding-right: 40px;
}
.CouponInvitaion-check-list {
  display: inline-block;
  color: #666666;
  font: normal normal normal 14px/33px Poppins;
  /* border-radius: 10px; */
}
.CouponInvitaion-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 3px 50px 5px 10px;
  /* background: #f9faff; */
  border: 1px solid #666666;
}
.CouponInvitaion-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 2px solid black;
  border-top: 2px solid black;
  padding: 3px;
  right: 10px;
  top: 30%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.CouponInvitaion-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}
.CouponInvitaion-check-list ul.items {
  padding: 15px;
  display: none;
  margin: 0;
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #008ded34;
  border-top: none;
}
.CouponInvitaion-check-list ul.items li {
  list-style: none;
}
.CouponInvitaion_dropdown span {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #666666;
  width: 200px;
  /* background: #f9faff 0% 0% no-repeat padding-box; */
  border-radius: 10px;
  border: 1px solid #666666;
}
.CouponInvitaion_dropdown label {
  font: normal normal normal 14px/33px Poppins;
  letter-spacing: 0px;
  padding-left: 10px;
  color: #111111;
}
.CouponInvitaion_Searchbar .form-control {
  height: 40px;
  width: 80%;
  font: normal normal normal 12px/16px Poppins;
  /* background: #f3f3fa 0% 0% no-repeat padding-box; */
  border: 1px solid #666666;
  /* border-radius: 20px; */
  padding-left: 12%;
  margin-left: 20px;
}
.CouponInvitaion_Searchbar input[type="search"] {
  margin-top: 8px;
  border-radius: 10px;
}
.CouponInvitaion_Searchbar .CouponInvitaion_serch-icon {
  display: block;
  position: relative;
  top: 16px;
  left: 50px;
  font-size: 15px;
}
/* CouponInvitaion Checkbox data */
.CouponInvitaion_checkbox_wrapper {
  background: #f8f8fb 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 20px 15px 20px 0px;
  padding: 20px 10px 0px 20px;
}
.btn_Select {
  font: normal normal normal 14px/33px Poppins;
  letter-spacing: 0px;
  color: #5a5eee;
  border: none;
  background-color: #f8f8fb;
  padding-left: 20px;
}
.btn_reset {
  font: normal normal normal 14px/33px Poppins;
  letter-spacing: 0px;
  color: #111111;
  background-color: #f8f8fb;
  border: none;
  float: right;
  padding-left: 55%;
}
.checkbox_wrapper {
  margin: 10px;
  overflow-y: visible;
  /* height: 90vh; */
  /* margin-bottom: 50px; */
}
.checkbox_wrapper label {
  background: #5a5eee 0% 0% no-repeat padding-box;
  padding: 3px 8px;
  color: #fff;
  font: normal normal normal 14px/29px Poppins;
  border-radius: 50%;
  margin-left: 30px;
  margin-bottom: 10px;
}
.checkbox_wrapper span {
  font: normal normal normal 14px/29px Poppins;
  letter-spacing: 0px;
  color: #111111;
  opacity: 1;
  padding-left: 10px;
}
.checkbox_wrapper input[type="checkbox"] {
  float: right;
  margin-right: 60px;
  margin-top: 10px;
}
.CouponInvitaion_button button {
  float: right;
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 5px 10px;
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  margin-right: 10px;
}
.CouponCodeView_wrapper {
  margin: 0px 10px 10px 0px;
}
.CouponCodeView_wrapper h5 {
  font: normal normal normal 14px/37px Poppins;
  font-weight: 500;
  letter-spacing: 0px;
  color: #5a5eee;
}
.CouponCodeView_wrapper hr {
  color: #5a5eee;
  width: 32%;
  position: relative;
  top: -20px;
  height: 3px;
  opacity: 1;
}
.CouponCodeView_Icon {
  padding-top: 8px;
  float: right;
  font-size: 23px;
  padding-right: 40px;
}
.CouponCodeView_Searchbar .form-control {
  height: 40px;
  width: 100%;
  font: normal normal normal 12px/16px Poppins;
  /* background: #f3f3fa 0% 0% no-repeat padding-box; */
  border: 1px solid #666666;
  /* border-radius: 20px; */
  padding-left: 10%;
  margin-left: 0px;
  margin-right: 10px;
}
.CouponCodeView_Searchbar input[type="search"] {
  margin-top: 8px;
  border-radius: 10px;
}
.CouponCodeView_Searchbar .CouponCodeView_serch-icon {
  display: block;
  position: relative;
  top: 16px;
  left: 40px;
  font-size: 15px;
}
.checkbox_wrapper i {
  float: right;
  position: relative;
  left: 10%;
  padding-top: 1px;
}
.CouponInvitaion_input_form select {
  border: 1px solid #666666;
  padding: 9px;
  border-radius: 10px;
  font-size: 14px;
  width: 100%;
}
.CouponInvitaion_input_form Select option {
  font-size: 12px;
}
.Searchbar .form-control {
  height: 40px;
  font: normal normal normal 12px/16px Poppins;
  background: #fdfdfd 0% 0% no-repeat padding-box;
  border: 1px solid #e9edf0;
  border-radius: 6px;
  /* padding-left: 30px; */
  margin-top: 20px;
}
.dropdown_data {
  background-color: #fdfdfd;
  width: 20%;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 6%;
  margin-left: -215px;
  /* padding: 5px; */
}
p.view_p:nth-child(odd) {
  background: white;
}

p.view_p:nth-child(even) {
  background: #e9fff9;
  border-radius: 10px;
}
p.view_p {
  /* padding: 5px; */
  margin-bottom: 0px !important;
}

.dropdown_data:empty {
  border: none;
}
/* .Searchbar .serch-icon {
  display: block;
  color: #6ecacd;
  position: relative;
  top: 30px;
  left: 25px;
} */
