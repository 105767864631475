/* Google Fonts Import Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("http://fonts.cdnfonts.com/css/segoe-ui-4?styles=18006,18004");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;

}
a {
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style-type: none;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  z-index: 100;
  transition: all 0.5s ease;
  background: #2a2f44 0% 0% no-repeat padding-box;
  opacity: 1;
}
.sidebar.close {
  width: 78px;
}
.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i {
  font-size: 30px;
  color: #11101d;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name {
  /* font-size: 22px;
  color: rgb(12, 11, 11); */
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
  font: normal normal normal 30px/40px Segoe UI;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-left: 65px;
  padding-top: 20px;
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .nav-links > li.active:before,
.sidebar .nav-links > li:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 4px;
  height: 100%;
  background: #ffffff;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  border-top-right-radius: 5px;
  border-top-right-radius: 5px;
}
.sidebar .nav-links li.active:before,
.sidebar .nav-links li:hover:before {
  opacity: 1;
}
.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .iocn-link {
  display: block;
}
.sidebar .nav-links li i {
  height: 38px;
  min-width: 48px;
  text-align: center;
  line-height: 60px;
  color: #707070;
  /* font-size: 20px; */
  font: normal normal normal 18px/33px Poppins;
  letter-spacing: 0px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar .nav-links li.active i,
.sidebar .nav-links li:hover i {
  color: #ffffff;
}
.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow {
  display: none;
}
.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li a .link_name {
  /* font-size: 16px; */
  font: normal normal normal 14px/33px Poppins;
  letter-spacing: 0px;
  /* font-weight: 400; */
  color: #707070;
  transition: all 0.4s ease;
}
.sidebar .nav-links li.active a .link_name,
.sidebar .nav-links li:hover a .link_name {
  color: #ffffff;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 70px;
  margin-top: -10px;
  background: #2a2f44 0% 0% no-repeat padding-box;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  /* color: #707070; */
  color: #ffffff;
  font: normal normal normal 14px/29px Poppins;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
  color: #ffffff;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  color: #ffffff;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font: normal normal normal 14px/29px Poppins;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details {
  background: none;
}
.sidebar.close .profile-details {
  width: 78px;
}
.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details img {
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
}
.sidebar .profile-details .job {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  opacity: 0.5;
  white-space: nowrap;
}
.sidebar .profile-details i.bx {
  min-width: 50px;
}

.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}
.sidebar .profile-details .job {
  font-size: 12px;
}
.home-section {
  position: relative;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
}
.sidebar.close ~ .home-section {
  left: 78px;
  width: calc(100% - 78px);
}
.home-section .home-content {
  height: 70px;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-color: #F5F7FF; */
  display: flex;
  align-items: center;
}
.home-section .home-content .bx-menu {
  color: #707070;
  font-size: 20px;
}
.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}

.home-section .home-content .Topbar_Right_Side {
  position: relative;
  right: -70%;
  padding-top: 20px;
}
.home-section .home-content .notification img {
  width: 25px;
  position: relative;
  right: -200%;
  top: 10px;
}
.My-Profile-Content {
  position: relative;
  right: -95%;
}
.My_Profile_Icon img {
  width: 40px;
  position: relative;
  top: 5px;
}
.My_Profile_Icon i {
  position: relative;
  right: -80%;
  top: -28px;
}
.Profile_link_name {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #020723;
  transition: all 0.4s ease;
  right: -11%;
  position: relative;
  top: -3px;
  opacity: 100%;
}
.Profile_content_name {
  font: normal normal normal 10px/33px Poppins;
  letter-spacing: 0px;
  color: #020723;
  transition: all 0.4s ease;
  right: -46%;
  position: relative;
  top: -20px;
  opacity: 70%;
}

@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}
.My-Profile-Content .sub-menu {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #5a5eee34;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, #fff);
  border-radius: 10px;
  width:170px;
  opacity: 1;
  font: normal normal normal 10px/23px Poppins;
  letter-spacing: 0px;
  padding: 15px 25px 10px 25px;
  margin-left: 100px;
  color: #111111;
  position: fixed;
  right: 1%;
  display: none;
  top:8%;
}
.My-Profile-Content .sub-menu li a {
  color: #111111;
}
.My-Profile-Content .sub-menu li .bx-menu {
  font: normal normal normal 10px/23px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
/* Dashboard Page Styles */
.dashboard-wrapper{
  padding-top: 40px;
  font: normal normal normal 35px/82px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  white-space: nowrap;
  background-color: #f3f3fa;
  height: 100vh;
  padding-left: 20% !important;
}
.sidebar.close ~ .dashboard-wrapper h1 {
  left: 78px;
  width: calc(100% - 78px);
  white-space: nowrap;
}
.dashboard-wrapper .date {
  /* margin-right: 25%; */
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 20px/57px Segoe UI;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 20px/57px Segoe UI;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  /* width:18%; */
  width:70%;
  float: right;
}
.dashboard-wrapper button sub {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 14px/27px Segoe UI;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 14px/27px Segoe UI;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 0.8;
}
.dashboard-wrapper #time{
    font: normal normal normal 16px/24px Segoe UI;
}
.dashboard-wrapper h5{
   margin-left: 20px;
   font: normal normal normal 14px/24px Segoe UI;
   color: #707070;
}
.dashboard-wrapper #Country{
   position: relative;
   top:-5%;
 }
.dashboard-wrapper p{
    margin-left: 20px;
    font: normal normal normal 12px/24px Segoe UI;
    position: relative;
    bottom: 30%;
}

.f-left {
  float: left;
}

.text-right {
  float: right;
  color: #020723;
}

.card-block {
  padding: 7%;
}

.home-card {
  background-color: #dadbfe;
  width: 80%;
}

.card-title {
  color: #020723;
}