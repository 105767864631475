/* Google Fonts Import Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("http://fonts.cdnfonts.com/css/segoe-ui-4?styles=18006,18004");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap");

.superadmin-account {
  background-color: #f3f3fa;
  height: 90vh;
  padding-left: 20% !important;
}
.superadmin-account h1 {
  text-align: left !important;
  font: normal normal normal 15px/40px Poppins;
  letter-spacing: 0px;
  color: #111111 !important;
  opacity: 1 !important;
  padding-top: 2%;
  /* padding-left: 5px; */
  font-weight: 500;
}
.superadmin-form-data {
  background-color: #e9edf0;
  width: 95%;
  padding: 15px 20px 10px 15px;
}
.superadmin-form-data h5 {
  font: normal normal normal 15px/40px Poppins;
  font-weight: 500;
  padding-left: 10px;
}
.superadmin-form-data button {
  font: normal normal normal 14px/40px Poppins;
  float: right;
  margin-right: 40px;
  color: #5a5eee;
  border: 2px solid #e9edf0;
  background-color: #e9edf0;
}
.superadmin-Form {
  background-color: #ffffff;
  width: 95%;
  height: 460px;
}
.superadmin-Form .superadmin-myprofile-circle {
  font: normal normal normal 20px/40px Poppins;
  height: 100%;
  position: relative;
  top: 50px;
  left: 50px;
  background-color: #5a5eee;
  border-radius: 50%;
  padding: 30px;
  color: #ffffff;
}
.superadmin-Form_wrapper label {
  text-align: left;
  font: normal normal normal 12px/40px Poppins;
  letter-spacing: 0px;
  color: #08134a;
  opacity: 0.6;
  padding-left: 8px;
}
.superadmin-Form_wrapper input {
  background: #e9ecff80 0% 0% no-repeat padding-box;
  border: 1px solid #e9edf0;
  font: normal normal normal 10px/40px Poppins;
  border-radius: 6px;
  color: #08134a;
  font-weight: 600;
  width: 100%;
  opacity: 1;
  height: 33px;
  margin-bottom: 20px;
}
.superadmin-Form_wrapper select {
  background: #e9ecff80 0% 0% no-repeat padding-box;
  border: 1px solid #e9edf0;
  font: normal normal normal 10px/40px Poppins;
  border-radius: 6px;
  color: #08134a;
  font-weight: 600;
  width: 100%;
  opacity: 0.7;
  height: 33px;
  margin-bottom: 20px;
  padding-left: 10px;
}
.superadmin-Form_wrapper i {
  color: #5a5eee;
  position: relative;
  left: 87%;
  top: -24%;
  cursor: pointer;
}
#update {
  background-color: #5a5eee;
  border: 1px solid #5a5eee;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 12px;
  border-radius: 5px;
  padding: 5px 32px 5px 32px;
  opacity: 1;
  margin: 10px 30px 20px 0px;
}
#cancel {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  font-size: 12px;
  font-weight: 500;
}
/* Password Reset Panel Content */

.panel-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30em;
  transform: translateX(100%);
  transition: 0.5s ease-out;
  /* overflow-x:hidden; */
}
.panel {
  position: absolute;
  top: 10%;
  bottom: 0;
  left: 0;
  height: 90%;
  right: -5%;
  background: #ffffff;
  overflow: auto;
  padding: 1em;
}

[type="text"]:checked ~ .panel-wrap {
  transform: translateX(0%);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  font-size: 18px;
}
h3 {
  margin: 0;
}
p {
  line-height: 1.4;
  margin: 2em 0 0 0;
}
[type="text"] {
  font-size: 1em;
}
.superadmin-password-reset {
  font: normal normal normal 20px/40px Poppins;
  height: 100%;
  position: relative;
  top: 20px;
  left: 10px;
  background-color: #5a5eee;
  border-radius: 50%;
  padding: 20px;
  color: #ffffff;
}
.superadmin-password-title h5 {
  font: normal normal normal 14px/29px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  position: relative;
  top: 15px;
  left: 40px;
}
.superadmin-password-title button {
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  color: #5a5eee;
  border: none;
  background-color: #fff;
  opacity: 1;
  position: relative;
  top: 5px;
  left: 40px;
}
.superadmin-password_close_Icon {
  padding-top: 15px;
  float: right;
  font-size: 23px;
  padding-right: 40px;
}
.superadmin-password_close_Icon a{
  color:#111;
}
.Reset_Password_Form {
  padding-top: 40px;
  align-items: center;
  justify-content: center;
  padding-left: 20%;
}
.Reset_Password_Form label {
  text-align: left;
  font: normal normal normal 13px/28px Poppins;
  letter-spacing: 0.56px;
  color: #020723;
  font-weight: 500;
  opacity: 1;
  padding-left: 20px;
}
.Reset_Password_Form input {
  background-color: #fcfcfd;
  border: 1px solid #fcfcfd;
  border-radius: 8px;
  font-size: 12px;
  box-shadow: 2px 2px 4px #f3f3fa;
  opacity: 1;
  color: #a9a9a9;
  width: 80%;
  margin: 10px;
}
.Reset_Password_Form i {
  position: relative;
  top: -40px;
  left: 75%;
  color: #999999;
}
.ProgressBar {
  width: 90%;
  padding-left: 10px;
  height: 1px;
  color: #5a5eee;
}
.progress {
  height: 8px;
  font-size: 6px;
}
.Reset_Password_Form button {
  background-color: #5a5eee;
  color: #ffffff;
  border: 1px solid #5a5eee;
  font-size: 14px;
  padding: 5px 15px 5px 15px;
  border-radius: 6px;
  float: right;
  margin-right: 70px;
  margin-top: 10px;
}
.Post-card-body textarea {
  background-color: #ffffff;
  width: 700px;
  position: relative;
  left: 42px;
  top: 10px;
  height: 280px;
  border: 1px solid #ffffff;
}
.Post-card-body textarea[placeholder] {
  padding: 10px;
  color: #999999;
}
/* Modal Pop Up */
.ReactModal__Content {
  width: 30%;
  height: 30%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 4px;
  opacity: 1;
}
.ReactModal__Content span img {
  width: 60px;
  height: 60px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.ReactModal__Content h4 {
  text-align: center;
  font: normal normal normal 14px/33px Poppins;
  letter-spacing: 0px;
  color: #6c6c6c;
  opacity: 1;
  padding-top: 10px;
}
.ReactModal__Content button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  height: 22%;
  background-color: #6c6c6c;
  border: none;
  color: #fcfcfd;
  font: normal normal normal 16px/33px Poppins;
  border-radius: 8px;
  margin-top: 20px;
}
