/* Google Fonts Import Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("http://fonts.cdnfonts.com/css/segoe-ui-4?styles=18006,18004");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.main-banner {
  margin: 0 auto;
  width: 85%;
}
.top-p {
  position: relative;
}
.login-banner img {
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  width: 85%;
  height: 100%;
  position: relative;
  top: 85px;
  left: 30px;
}
.Logo img {
  height: 135px;
  width: 135px;
  left: 90px;
  position: absolute;
  top: 25px;
}
@media screen and (max-width: 320px) {
  .login-banner {
    height: 350px;
  }
}
.right-content {
  text-align: left;
  padding-top: 145px;
  width: 350px;
  margin-left: 100px;
  padding-right: 10px;
}
.signin {
  padding-top: 20px;
}
.signin h1 {
  font-size: 1.2rem;
  font-weight: 600;
}
.signin p {
  text-align: left;
  font: normal normal 600 30px/38px Poppins;
  letter-spacing: 0.2px;
  color: #020723;
  opacity: 1;
}
.p2 p
{
    text-align: left;
    font: normal normal normal 14px/18px Poppins;
    letter-spacing: 0px;
    color: #020723;
    opacity: 0.87;
    margin-bottom: 5px;
}
.p3 p
{
    text-align: left;
    font: normal normal normal 10px/16px Poppins;
    letter-spacing: 0.4px;
    color: #020723;
    opacity: 0.7;
    margin-bottom: 5px;
}
/* .Student-Label
{
    top: 508px;
    left: 106px;
    width: 131px;
    height: 26px;
    text-align: left;
    font: normal normal normal 14px/28px Poppins;
    letter-spacing: 0.14px;
    color: #020723;
    opacity: 1;
}
.Student-inputfield
{
    width: 350px;
    height: 45px;
    background: #F2F7FF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
} */
.Signin_form input[type='email']
{
    width:350px;
    height:42px;
    background: #F2F7FF 0% 0% no-repeat padding-box;
    border: #F2F7FF;
    border-radius: 8px;
    position: relative;
     /* border: 1px solid #F8364F; */
     /* border: 1px solid #007b85; */
}
.Signin_form input[type='password']
{
    width:350px;
    height:42px;
    background: #F2F7FF 0% 0% no-repeat padding-box;
    border: #F2F7FF;
    border-radius: 8px;
    position: relative;
     /* border: 1px solid #F8364F; */
    /* border: 1px solid #007b85; */
}
/* .invalid-error{
    border: 1px solid #F8364F;
} */
.error-msg{
  text-align: left;
font: normal normal 600 12px/26px Poppins;
letter-spacing: 0px;
color: #F82641;
}
.Signin-Button button
{
    width: 350px;
    height: 42px;
    background: #6ECACD 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: #007B85;
    color:#FFFFFF;
    opacity: 0.87;
 }
 .Signin_form  .form-group {
    margin-bottom: 15px;
    position: relative;
}
.Signin_form  .form-group label{
    margin-bottom: 0px;
    font-weight: 400;
} 
.Signin_form .form-check-label{
    padding-top: 4px;
    padding-left: 8px;
}
.Signin_form .form-check-input
{
    border:3px solid #007B85;
    top: 455px;
    left: 1213px;
    width: 20px;
    height: 20px;
    opacity:1;

}
.Signin_form .form-check-input:checked {
    background-color: #007b85;
    border-color: #007b85;
}
.Signin_form ,.form-check-input:focus ,.btn-check:focus + .btn-primary, .btn-primary:focus{
    box-shadow: none !important;
}
.Signin_form label{
  font: normal normal normal 12px/28px Poppins;
letter-spacing: 0.44px;
color: #020723;
opacity: 1;
}
.Signin_form  a{
  font-size: 12px;
  float: right;
  position: relative;
  top:8px;
}
.Signin_form input::placeholder{
  font-size: 14px;
}
/* SignOut CSS */
.overlay{
  position: absolute;
  top: 0;
  left: 0;
  background: #555555;
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  z-index: 1;
}
.signout-card
{
    background: #fff;
    margin: 0 auto;
    width: 550px;
    height: 230px;
    z-index: 2;
    border-radius: 10px;
    padding: 30px;
    position: absolute;
    top: 230px;
    left:40%;
    border:2px solid #fff;
}
.signout-card h6
{
    position: relative;
    color:#5A5EEE;
    font-size:15px;
    left: 25%;
    bottom:25px;
}
.signout-card p
{
    position: relative;
    color:#707070;
    font-size:12px;
    left: 25%;
    bottom:30px;
}
.signout-btn 
{
    text-align: center;
}
.signout-btn button
{
    width:120px;
    height:30px;
    border:none;
    color:white;
    border-radius: 5px;
    font-size: 14px;
}
.signout-btn .button1
{
    background-color: #5A5EEE;
}

.signout-btn .button2
{
    background-color: #999999;
}