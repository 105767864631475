@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("http://fonts.cdnfonts.com/css/segoe-ui-4?styles=18006,18004");

/* * {
  margin: 0;
  padding: 0;
} */
body {
  font-family: "Poppins", sans-serif;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  overflow: hidden;
  /* font-family: 'Segoe UI', sans-serif; */
}
.dashboard h1 {
  padding-top: 40px;
  padding-left: 20%;
  font: normal normal normal 35px/82px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  
}
