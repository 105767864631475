/* Google Fonts Import Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("http://fonts.cdnfonts.com/css/segoe-ui-4?styles=18006,18004");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap");

.UserManagement_wrapper {
  background-color: #f3f3fa;
  /* height: calc(120vh - 60px); */
  height: 90vh;
  padding-left: 20% !important;
  padding-top: 20px;
  padding-right: 40px;
  overflow-y:scroll;
  padding-bottom: 5% !important;
}
.user-data h5 {
  font: normal normal normal 20px/49px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-weight: 500;
}
.user-data button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  border: none;
  color: #fafafa;
  font: normal normal normal 12px/27px Segoe UI;
  padding: 8px 20px 8px 20px;
  float: right;
}
.user-management-wrapper {
  background-color: #ffffff;
  width: 100%;
  height: 450%;
  padding: 15px 20px 10px 15px;
  margin-top: 10px;
  /* overflow-y: scroll; */
}
.user-management-wrapper .Searchbar .form-control {
  height: 40px;
  width: 18%;
  font: normal normal normal 12px/16px Poppins;
  background: #f3f3fa 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3fa;
  border-radius: 20px;
  padding-left: 30px;
  margin-top: 20px;
}
.Searchbar .serch-icon {
  display: block;
  position: relative;
  top: 25px;
  left: 32px;
  font-size: 20px;
}
/* Checkbox and Dropdown Styles */
.usercodeCreator-check-list {
  display: inline-block;
  color: #666666;
  font: normal normal normal 12px/15px Poppins;
  border-radius: 5px;
  text-align: justify;
}
.user_input_form select {
  width: 40%;
  height: 30px;
}
.usercodeCreator-check-list:hover {
  background-color: #5a5eee;
  opacity: 100%;
}
.usercodeCreator-check-list .css-1s2u09g-control {
  background-color: #f8f8fb;
  color: #fff;
}
/* .usercodeCreator-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 2px 30px 2px 10px;
  border: 1px solid #ccc;
  color: #707070;
  border: 1px solid #f8f8fb;
  background: #f8f8fb 0% 0% no-repeat padding-box;
  border-radius: 8px;
}
.usercodeCreator-check-list .anchor:hover {
  background-color: #5a5eee;
  border: 1px solid #5a5eee;
  color: #ffffff;
}
.usercodeCreator-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 2px solid #707070;
  border-top: 2px solid #707070;
  padding: 2px;
  right: 10px;
  top: 40%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.usercodeCreator-check-list ul.items {
  padding: 0px 3px 0px 3px;
  display: none;
  margin: 0;

  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #008ded34;
  border-top: none;
  text-align: left;
}
.usercodeCreator-check-list ul.items li {
  list-style: none;
  color: #666666;
  background: #f8f8fb 0% 0% no-repeat padding-box;
  border-radius: 10px;
} */
/* User Table Css */

#user-table tr th {
  /* font: normal normal medium 25px/30px Poppins !important; */
  font-size: 13px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  text-align: center;
  padding-top: 30px;
}
#user-table tr {
  border-bottom: 5px solid #f8f8fb;
  height: 60px;
}
#user-table tr td {
  font-size: 12px;
  color: #555555;
  font-style: Poppins;
  text-align: center;
}
/* Create New User Panel */
.CreateNewUser_Wrapper {
  height: 90vh;
  padding-left: 0% !important;
}
.CreateNewUser_buttons button {
  font: normal normal normal 16px/41px Poppins;
  font-weight: 500;
  letter-spacing: 0px;
  color: #0b0b0b;
  border: none;
  background-color: #fff;
  cursor: pointer;
  margin-left: 10px;
}
.CreateNewUser_buttons button:focus,
.CreateNewUser_buttons button:active {
  color: #5a5eee;
  cursor: pointer;
}
.CreateNewUser_buttons button hr {
  position: relative;
  top: -25px;
}
.CreateNewUser_buttons button hr:focus,
.CreateNewUser_buttons button hr:active,
.CreateNewUser_buttons button hr:hover {
  border-bottom: 5px solid #5a5eee;
}
.CreateNewUser_buttons hr {
  position: relative;
  top: -30px;
  color: #d3d3ff;
}
/* .CreateNewUser_tabset > input[type="radio"] {
  position: absolute;
  left: -200vw;
}
.CreateNewUser_tabset .tab-panel {
  display: none;
  background-color: #fff;
  width: 95%;
  height: 500px;
  padding: 0px 5px;
}

.CreateNewUser_tabset
  > input:first-child:checked
  ~ .tab-panels
  > .tab-panel:first-child,
.CreateNewUser_tabset
  > input:nth-child(3):checked
  ~ .tab-panels
  > .tab-panel:nth-child(2),
.CreateNewUser_tabset
  > input:nth-child(5):checked
  ~ .tab-panels
  > .tab-panel:nth-child(3),
.CreateNewUser_tabset
  > input:nth-child(7):checked
  ~ .tab-panels
  > .tab-panel:nth-child(4),
.CreateNewUser_tabset
  > input:nth-child(9):checked
  ~ .tab-panels
  > .tab-panel:nth-child(5),
.CreateNewUser_tabset
  > input:nth-child(11):checked
  ~ .tab-panels
  > .tab-panel:nth-child(6) {
  display: block;
}
.CreateNewUser_tabset > label {
  position: relative;
  display: inline-block;
  padding: 5px 25px 10px 0px;
  cursor: pointer;
  text-align: left;
  font: normal normal normal 16px/41px Poppins;
  font-weight: 500;
  letter-spacing: 0px;
  color: #0b0b0b;
}
.CreateNewUser_tabset #tab1 hr {
  position: relative;
  top: -22px;
  color: #5a5eee;
  height: 3px;
  opacity: 100%;
}
.CreateNewUser_tabset #tab2 hr {
  position: relative;
  top: -22px;
  color: #5a5eee;
  height: 3px;
  opacity: 100%;
}
.CreateNewUser_tabset > label::before,
.CreateNewUser_tabset > label::after {
  content: "";
  position: absolute;
  left: 15px;
  bottom: 10px;
  width: 22px;
  height: 4px;
}

.CreateNewUser_tabset > label:hover,
.CreateNewUser_tabset > input:focus + label {
  color: #5a5eee;
  opacity: 1;
} */
.UserMangement-create-form {
  padding-left: 20px;
}
.UserMangement-create-form label {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #707070;
}
.UserMangement-create-form input {
  background: #f8f8fb 0% 0% no-repeat padding-box;
  border: 1px solid #d3d3ff;
  opacity: 0.5;
  width: 90%;
  padding: 3px;
  box-sizing: border-box;
  margin-bottom: 20px;
  border-radius: 5px;
  font: normal normal normal 12px/25px Poppins;
}
#userClose_Icon {
  position: relative;
  /* top: -45px; */
  left: 30%;
}
.horizontal_Line hr {
  position: relative;
  top: -40px;
  color: #b8898929;
}
.UserMangement-create-form select {
  background: #f8f8fb 0% 0% no-repeat padding-box;
  border: 1px solid #d3d3ff;
  opacity: 0.5;
  margin-bottom: 20px;
  border-radius: 5px;
  width: 95%;
  padding: 5px;
  font: normal normal normal 12px/33px Poppins;
  opacity: 100%;
}
.UserMangement-create-form select:focus,
.UserMangement-create-form select:hover {
  background-color: #5a5eee;
  color: #fff;
  border: 1px solid #5a5eee;
}
.UserMangement-create-form option {
  font: normal normal normal 12px/33px Poppins;
  /* background: #5A5EEE 0% 0% no-repeat padding-box; */
  background: #f8f8fb 0% 0% no-repeat padding-box;
  letter-spacing: 0px;
  color: #666666;
}
.UserMangement_button #Save_button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  opacity: 1;
  padding: 0px 15px;
  float: right;
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 40px;
  margin-top: 20px;
}
.UserMangement_button #Next_button button {
  background: #707070 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  opacity: 1;
  padding: 0px 15px;
  float: right;
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 40px;
  margin-top: 20px;
}
.Permissions_form_data select{
  width: 85%;
  margin-left: 0px;
  border-radius: 8px;
  border: 1px solid none;
  font: normal normal normal 14px/33px Poppins;
  letter-spacing: 0px;
  color: #707070;
  height: 40px;
  padding-left: 3%;

}
.Permissions_form_data Select option{
  color: #707070;
  font: normal normal normal 12px/33px Poppins;
}
/* .Permissions_form_data .dropdown-toggle{
  color:#5a5eee;
} */
/* .Permissions_form_data .permission_multiselect_dropdown {
  min-height: 5px;
  width: 85%;
  margin-left: 0px;
  border-radius: 8px;
  border: 1px solid none;
  font: normal normal normal 14px/33px Poppins;
  letter-spacing: 0px;
  color: #707070;
} */
/* .search-wrapper {
  height: 40px;
}
.searchBox {
  height: 20px;
}
.optionContainer {
  font: normal normal normal 12px/33px Poppins;
  white-space: nowrap;
  line-height: 0px;
}
.Permissions_form_data ::placeholder {
  color: #707070;
  font-weight: 900;
  opacity: 50%;
}
.Permissions_form_data #permission_icon1 {
  position: relative;
  left: 90%;
  top: -45%;
  font-size: 12px;
}
.Permissions_form_data #permission_icon2 {
  position: relative;
  left: 90%;
  top: -45%;
  font-size: 12px;
} */
.Permissions_form-checked-data {
  background: #f8f8fb 0% 0% no-repeat padding-box;
  border-radius: 4px;
  justify-content: center;
  text-align: center;
  padding: 10px;
}
.Permissions_form-checked-data input[type="text"] {
  border: 1px solid #666666;
  border-radius: 5px;
  font: normal normal normal 12px/25px Poppins;
  padding-left: 10px;
}
.Permissions_form-checked-data input[type="checkbox"] {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #0000000d;
  border: 1px solid #a2a2a2;
  border-radius: 6px;
}
.Permissions_form-checked-data ::placeholder {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  padding-left: 10px;
  color: #707070;
}
.Permissions_form_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: none;
  color: #fff;
  font: normal normal normal 12px/33px Poppins;
  padding: 0px 15px 0px 15px;
  margin-top: 40px;
  margin-right: 20px;
  float: right;
}
/* User Account Tabs */
.useraccount_tabs {
  background: #fff;
  position: relative;
  margin-bottom: 50px;
  margin-top: 20px;
}
.useraccount_tabs > input,
.useraccount_tabs > span {
  width: 20%;
  height: 60px;
  line-height: 60px;
  position: absolute;
  top: 0;
}
.useraccount_tabs > input {
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
  z-index: 99;
}
.useraccount_tabs > span {
  /* background: #f0f0f0; */
  text-align: center;
  overflow: hidden;
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  /* color: #5A5EEE; */
}
.useraccount_tabs span hr {
  width: 60%;
  position: relative;
  top: -20px;
  left: 22px;
  height: 2px;
  opacity: 1px;
}
.useraccount_tabs > span i,
.useraccount_tabs > span {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.useraccount_tabs > input:hover + span {
  background: rgba(255, 255, 255, 0.1);
}
.useraccount_tabs > input:checked + span {
  background: #fff;
}
.useraccount_tabs > input:checked + span,
.useraccount_tabs > input:hover + span {
  color: #5a5eee;
}
#tab-1,
#tab-1 + span {
  left: 0;
}

#tab-2,
#tab-2 + span {
  left: 15%;
}

.useraccount_tab-content {
  padding: 20px 20px 20px 0px;
  width: 100%;
  min-height: 340px;
}

.useraccount_tab-content section {
  width: 100%;
  display: none;
}

.useraccount_tab-content section h1 {
  margin-top: 15px;
  font-size: 100px;
  font-weight: 100;
  text-align: center;
}

#tab-1:checked ~ .useraccount_tab-content #tab-item-1 {
  display: block;
}

#tab-2:checked ~ .useraccount_tab-content #tab-item-2 {
  display: block;
}
/* effect-1 */

.effect-1 > input:checked + span {
  background: #fff;
}
/* User Account Form Css */

.useraccount_tab-content {
  padding-left: 20px;
}
.useraccount_tab-content label {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-weight: 900;
}
.useraccount_tab-content input {
  background: #f8f8fb 0% 0% no-repeat padding-box;
  border: 1px solid #d3d3ff;
  opacity: 0.5;
  width: 90%;
  padding: 0px 0px 0px 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #2a2f44;
}
.useraccount_tab-content select {
  background: #f8f8fb 0% 0% no-repeat padding-box;
  border: 1px solid #d3d3ff;
  opacity: 0.5;
  margin-bottom: 20px;
  border-radius: 5px;
  width: 95%;
  padding: 5px;
  font: normal normal normal 12px/33px Poppins;
  color: #2a2f44;
  font-weight: 900;
  opacity: 100%;
}
.useraccount_tab-content select:focus,
.useraccount_tab-content select:hover {
  background-color: #5a5eee;
  color: #fff;
  border: 1px solid #5a5eee;
}
.useraccount_tab-content option {
  font: normal normal normal 12px/33px Poppins;
  /* background: #5A5EEE 0% 0% no-repeat padding-box; */
  background: #f8f8fb 0% 0% no-repeat padding-box;
  letter-spacing: 0px;
  color: #666666;
}
.useraccount_form input {
  font: normal normal normal 12px/33px Poppins;
  color: #2a2f44;
  opacity: 1;
  font-weight: 900;
}
.useraccount_form i {
  position: relative;
  left: 80%;
  top: -40%;
  color: #5a5eee;
  font: normal normal normal 12px/27px Poppins;
}
.UserAccount_Edit_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: none;
  float: right;
  margin-right: 40px;
  padding: 0px 15px;
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
}
.UserAccount_Update_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: none;
  float: right;
  margin-right: 40px;
  padding: 0px 15px;
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
}
/* UserAccount Reset Password Panel */
.UserAccount_panel-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30em;
  transform: translateX(100%);
  transition: 0.5s ease-out;
}
.UserAccount_panel {
  position: absolute;
  top: 10%;
  bottom: 0;
  left: 0;
  height: 90%;
  right: -5%;
  background: #ffffff;
  overflow: auto;
  padding: 1em;
}

[type="text"]:checked ~ .UserAccount_panel-wrap {
  transform: translateX(0%);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  font-size: 18px;
}
h3 {
  margin: 0;
}
p {
  line-height: 1.4;
  margin: 2em 0 0 0;
}
[type="text"] {
  font-size: 1em;
}
.UserAccount-password-reset {
  font: normal normal normal 20px/40px Poppins;
  height: 100%;
  position: relative;
  top: 20px;
  left: 10px;
  background-color: #5a5eee;
  border-radius: 50%;
  padding: 20px;
  color: #ffffff;
}
.UserAccount-password-title h5 {
  font: normal normal normal 14px/29px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  position: relative;
  top: 15px;
  left: 40px;
}
.UserAccount-password-title button {
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  color: #5a5eee;
  border: none;
  background-color: #fff;
  opacity: 1;
  position: relative;
  top: 5px;
  left: 40px;
}
.UserAccount-password_close_Icon {
  padding-top: 15px;
  float: right;
  font-size: 23px;
  padding-right: 40px;
}
.UserAccount_Reset_Password_Form {
  padding-top: 40px;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
}
.UserAccount_Reset_Password_Form label {
  text-align: left;
  font: normal normal normal 13px/28px Poppins;
  letter-spacing: 0.56px;
  color: #020723;
  font-weight: 500;
  opacity: 1;
  padding-left: 20px;
}
.UserAccount_Reset_Password_Form input {
  background-color: #fcfcfd;
  border: 1px solid #fcfcfd;
  border-radius: 8px;
  font-size: 12px;
  box-shadow: 2px 2px 4px #f3f3fa;
  opacity: 1;
  color: #a9a9a9;
  width: 80%;
  margin: 10px;
  padding: 10px;
}
.UserAccount_Reset_Password_Form i {
  position: relative;
  top: -43px;
  left: 75%;
  color: #999999;
}
.UserAccount_ProgressBar {
  width: 80%;
  padding-left: 10px;
  height: 1px;
  color: #5a5eee;
}
/* .progress {
  height: 8px;
  font-size: 6px;
} */
.UserAccount_Reset_Password_Form button {
  background-color: #5a5eee;
  color: #ffffff;
  border: 1px solid #5a5eee;
  font-size: 14px;
  padding: 5px 15px 5px 15px;
  border-radius: 6px;
  float: right;
  margin-right: 70px;
  margin-top: 10px;
}
/* Permission Tab CSS */
.Permission_tab_form select {
  border: 1px solid #666666;
  border-radius: 5px;
  width: 70%;
}
.Permission_tab_form i {
  color: #666666;
  font-size: 12px;
}
.UserAccount_Permissions_form-checked-data {
  background: #f8f8fb 0% 0% no-repeat padding-box;
  border-radius: 4px;
  justify-content: center;
  text-align: center;
  padding: 25px;
}
.UserAccount_Permissions_form-checked-data input[type="text"] {
  border: 1px solid #666666;
  border-radius: 5px;
}
.UserAccount_Permissions_form-checked-data input[type="checkbox"] {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #0000000d;
  border: 1px solid #a2a2a2;
  border-radius: 6px;
}
.UserAccount_Permissions_form-checked-data ::placeholder {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  padding-left: 10px;
  color: #707070;
}
.UserAccount_Permissions_form_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: none;
  color: #fff;
  font: normal normal normal 12px/33px Poppins;
  padding: 0px 15px 0px 15px;
  margin-top: 20px;
  margin-right: 20px;
  float: right;
}
/* Modal Pop Up */
.ReactModal__Content {
  width: 30%;
  height: 30%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 4px;
  opacity: 1;
}
.ReactModal__Content span img {
  width: 60px;
  height: 60px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.ReactModal__Content h4 {
  text-align: center;
  font: normal normal normal 14px/33px Poppins;
  letter-spacing: 0px;
  color: #6c6c6c;
  opacity: 1;
  padding-top: 10px;
}
.ReactModal__Content button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  height: 22%;
  background-color: #6c6c6c;
  border: none;
  color: #fcfcfd;
  font: normal normal normal 16px/33px Poppins;
  border-radius: 8px;
  margin-top: 20px;
}
/* Plus(+) Cirlce CSS */
.checkbox_add_data fieldset {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #5a5eee3b;
  border-radius: 10px;
  padding-left: 20px;
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #666666;
}
/* .checkbox_add_data input[type="checkbox"] {
  width:15px;

} */
